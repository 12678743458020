.marketing-banner-container {
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  img {
    width: auto;
    height: auto;
    object-fit: cover;

    @media (max-height: 790px) {
      width: 100%;
      height: 367px;
    }

    @media (max-height: 690px) {
      width: 100%;
      height: 265px;
    }
  }

  .marketing-content {
    padding: 1rem;
    text-align: center;
    margin-top: 1rem;

    @media (min-width: 640px) {
      padding: 1.5rem;
    }

    @media (min-width: 1024px) {
      grid-column: span 2;
      padding: 2rem;
    }

    @media (min-width: 1280px) {
      padding: 2.5rem;
    }

    .title {
      font-size: 1.25rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      margin-top: 25px;
    }

    h2 {
      font-family: "Jost", sans-serif;

      margin-top: 1.5rem;
      font-size: 2.25rem;
      font-weight: 900;
      text-transform: uppercase;

      @media (min-width: 640px) {
        font-size: 2.5rem;
      }

      @media (min-width: 1024px) {
        font-size: 3rem;
      }

      .discount {
        font-size: 2rem;

        @media (min-width: 640px) {
          font-size: 2.5rem;
        }

        @media (min-width: 1024px) {
          font-size: 3rem;
        }
      }

      .subtitle {
        display: block;
        font-size: 0.8rem;
        margin: 40px 0 20px 0;
      }
    }

    .cta-button {
      margin-top: 2rem;
      display: inline-block;
      width: 100%;
      background-color: #000;
      padding: 1rem;
      font-size: 0.875rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: #fff;
      cursor: pointer;

      &:hover {
        background-color: #333;
      }
    }

    .offer-info {
      margin-top: 2rem;
      font-size: 0.75rem;
      font-weight: 500;
      text-transform: uppercase;
      color: #777;
    }
  }
}
