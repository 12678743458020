@keyframes circle {
  0% {
    clip-path: polygon(0 45%, 50% 60%, 100% 45%, 100% 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 104%, 50% 106%, 100% 103%, 100% 100%, 0 100%);
  }
}

.scroll-container {
  height: 120vh;
  overflow-y: scroll; /* Allows vertical scrolling */
  overflow-x: hidden;
  scroll-behavior: smooth; /* Smooth scrolling */
}

.snap-enabled {
  scroll-snap-type: y mandatory; /* Enable scroll snapping */
}

.section {
  // height: 100vh; /* Full viewport height for each section */
  scroll-snap-align: start;
}

.preloaderWrapper {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  filter: drop-shadow(5px 10px 10px rgba(0, 0, 0, 0.5));
}

.preloaderClippath {
  background: $theme-primary-color;
  opacity: 0.9;
  height: 100%;
  width: 100%;
  clip-path: polygon(0 45%, 50% 60%, 100% 45%, 100% 100%, 0 100%);
}

.preloader2 {
  margin-bottom: 280px;
}

.preloaderHide {
  animation: circle 2s;
}

.inviteBannerWrapper {
  top: 0;
  width: 100%;
  z-index: 11;
}

.primaryText {
  color: $theme-primary-color !important;
}
// Hero
.heroImage {
  height: 660px;
  width: 420px;
  object-fit: cover;

  @include media-query(1200px) {
    height: 500px;
    width: 420px;
  }

  @include media-query(450px) {
    height: 360px;
    width: 420px;
  }
}

.hero4Image {
  height: 784px;
  width: 700px;
  object-fit: cover;

  @include media-query(1200px) {
    height: 405px;
    width: 450px;
  }
}

.heroSeparator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
}

.heroDate {
  font-family: "Jost", sans-serif;
  font-size: 36px !important;
  line-height: 1em;
  padding-top: 15px;
  color: #657150;

  @include media-query(990px) {
    font-size: 28px !important;
  }
}

.hero-indian {
  background-color: $creme;
}

.home-indian-heart {
  background: $creme !important;
}

.type-writer {
  span {
    font-size: 18px;
  }

  .type-animate {
    white-space: break-spaces !important;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  100% {
    border-color: transparent;
  }
}

// Common classes
.orangeText {
  display: block;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: $theme-primary-color-s2;

  @include media-query(767px) {
    line-height: 35px;
    margin-bottom: 0;
  }

  @include media-query(575px) {
    font-size: 18px;
  }
}

.square {
  border-radius: 0 !important;
}

.ptp200 {
  padding-top: 200px !important;

  @media screen and (max-width: 991px) {
    padding-top: 30px;
  }
}

.zIndex {
  z-index: 1;
}

.mBtm30 {
  margin-bottom: 30px;
}

.ptp30 {
  padding-top: 30px;
}

.subContainer {
  margin-top: 90px;
}

.inviteeWrapper {
  min-height: 800px;
  // background-color: $section-bg-color-s3;
  // background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .innerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 25px;

    .wpo-invitee-section-wrapper {
      margin-bottom: 35px;

      p {
        font-size: 22px;
        margin-bottom: 10px;
      }
    }
  }

  .warm-regards {
    white-space: pre;
    text-align: center;
  }
}

.left-shape {
  position: absolute;
  left: 0;
  bottom: -45%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
  max-width: 500px;

  @include media-query(768px) {
    max-width: 250px;
    bottom: -35%;
  }
}

.right-shape {
  position: absolute;
  right: 0;
  top: 20%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  z-index: -1;
  max-width: 500px;

  @include media-query(768px) {
    max-width: 350px;
  }
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.bannerSection {
  p {
    color: black;
    font-size: 18px;
  }

  .title {
    font-size: 60px;
  }

  .bannerButton {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 70px 0;

    button {
      height: 45px;
      background: #e35d5d;
      border: none;
      color: $white;
      width: auto;
      font-size: 16px;
      padding: 10px 36px;
      border-radius: 24px;
    }
  }
}

// Couple section
.coupleSection2Groom {
  order: 2;
  -webkit-order: 2;

  @include media-query(767px) {
    margin-top: 52px;
  }
}

.coupleSectionImg {
  top: 35% !important;
}

.coupleImage {
  height: 410px;
  width: 458px;
  object-fit: cover;

  @include mq-min-width(320px) {
    height: 310px;
    width: 330px;
  }

  @include mq-min-width(767px) {
    height: 390px;
    width: 458px;
  }
}

.coupleImage4 {
  height: 410px;

  @include media-query(767px) {
    height: 320px;
  }

  @include media-query(467px) {
    height: 250px;
  }

  @include media-query(380px) {
    height: 200px;
  }
}

// Couple section message
.couple-section-message {
  margin: 20px 0 20px 0;
  text-align: center;
}

@media (max-width: 767px) {
  .couple-section-message {
    margin: 20px 0 20px 0;
  }
}

.couple-section-message span {
  display: block;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #c8a898;
}

@media (max-width: 767px) {
  .couple-section-message span {
    font-size: 25px;
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .couple-section-message span {
    font-size: 18px;
  }
}

.couple-section-message h2 {
  font-family: Sail;
  font-size: 32px;
  font-weight: 400;
  margin: 0;
  position: relative;
  color: #727667;
  display: inline-block;
  position: relative;
  margin-top: 20px;
}

@media (max-width: 575px) {
  .couple-section-message h2 {
    font-size: 25px;
  }
}

.couple-section-message p {
  font-size: 18px;
}

.map {
  width: 100%;
  height: 600px;
}

// Map section
.venueContainer {
  background-image: url("../../images/venue/venue-bg-desktop.jpg");

  @include media-query(767px) {
    background-image: url("../../images/venue/venue-bg-mobile.jpg");
    padding: 12px;
  }

  .venueWrapper {
    display: flex;
    justify-content: center;
  }
}

.venueSection {
  p {
    font-size: 1rem;
    line-height: 24px;
  }

  .mapIcon {
    height: 20px;
    width: 20px;
  }

  .addressWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-bottom: 10px;
    padding: 0 40px;

    @include media-query(767px) {
      padding: 0 4px 5px;
    }

    @include media-query(512px) {
      padding: 0 16px 5px;
    }
  }

  .optionContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 30px;

    p {
      font-size: 16px;
      color: grey;
    }
  }

  .option {
    display: flex;
    gap: 10px;
  }

  a {
    color: $theme-primary-color-s2;
  }
}

.hashtagContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq-min-width(1200px) {
    margin-bottom: 30px;
  }

  img {
    height: 6rem;
  }
}

.fontJost {
  font-family: "Jost", sans-serif;
}

.amantrika-home {
  .right-shape {
    top: 120px;
    display: none;

    @include media-query(900px) {
      display: block;
    }
  }

  .title {
    font-size: 2.5rem;
    line-height: 3.12rem;
    margin: 45px 0 25px 0;

    @include media-query(1200px) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  .amantrika-btn {
    background-color: #e35d5d !important;
    color: white;
    font-size: 16px;
    width: 175px;
  }

  .img-1 {
    height: 770px;

    @include media-query(1200px) {
      height: 550px;
    }
  }
}

.writeReviewWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.writeReviewContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 265px;

  p {
    margin-bottom: 5px;
  }

  span {
    color: rgba(101, 113, 80);
  }

  .reviewBtn {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e35d5d;
    color: white;
    width: 120px;
    font-size: 16px;
    align-self: center;
  }

  .inputBox {
    background: transparent;
    border: 1px solid grey;
    color: white;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
}

.reviewBtn {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e35d5d;
  color: white;
  width: 120px;
  font-size: 16px;
  align-self: center;
}

.footerLink {
  color: #fff;
  font-size: 18px;

  &:hover {
    color: #fff;
  }
}

.footerIcons {
  color: WHITE !important;
  font-size: 20px;
}

.story-text {
  .eventTitle {
    font-size: 24px;
    color: $theme-primary-color-s2;
    margin-bottom: 3px;
    font-family: "Jost", sans-serif;
  }

  .event-row {
    display: flex;
    gap: 10px;
  }

  .right-align {
    flex-direction: row-reverse;

    @include media-query(990px) {
      flex-direction: row;
    }
  }
}

.quoteContainer {
  .content {
    width: 100%;
  }

  .photos {
    display: flex;
    justify-content: center;
  }

  .thumbnails {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s;
  }

  .thumbnails img {
    width: 100%;
    object-fit: cover;
    // object-position: 50% 20%;
  }

  .black {
    text-align: center;
    background: #000;
    background-blend-mode: multiply;
    color: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.7;
  }

  .title {
    color: #fff;
    font-size: 12px;
    opacity: 0.8;
    align-self: center;
    position: absolute;
    z-index: 10;
    letter-spacing: 4px;
    margin: 0 12px;
    text-align: center;
    font-weight: 200;
    max-width: 600px;
  }

  .black,
  .title {
    transition: opacity 0.3s;
  }
}

.eventContainer {
  padding-bottom: 20px;
}

.zoom {
  -webkit-animation: kenburn 25s 2s alternate infinite;
  -animation: kenburn 25s 2s alternate infinite;
}

.display-none-desktop {
  @media screen and (min-width: 575px) {
    display: none;
  }
}
