/*---------------------------
	Fonts
----------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800&family=Noto+Kufi+Arabic:wght@300;400;500;600;700&family=Sail&display=swap");

// fonts
$base-font-size: 15;
$base-font: "Jost", sans-serif;
$heading-font: "Sail", cursive;

// color
$dark-gray: #101010;
$body-color: #5c5c5c;
$white: #fff;
$light: #8188a9;
$black: #000;
$small-black: #333;
$cyan: #848892;
$creme: #f8eee1;

$theme-primary-color: #657150;
$theme-primary-color-s2: #c8a898;
$theme-primary-color-s3: #a5aa9c;
$theme-primary-color-s4: #e5d1c7;
$body-bg-color: #fff;
$section-bg-color: #fcfdfa;
$section-bg-color-s2: #faf2ee;
$section-bg-color-s3: #f1f3ee;
$section-bg-color-s4: #f5f5f5;
$section-bg-color-indian: #f8eee1;
$text-color: #5c5c5c;
$text-light-color: #7b7b7b;
$text-dark-color: #403e3e;
$heading-color: $dark-gray;
$heading-color2: #3f3f3f;
$border-color: #f3ece9;
$border-color-s2: #e4ebf2;
