/*--------------------------------------------------------------
19. coming-soon-section
--------------------------------------------------------------*/

.wpo-coming-soon-area {
    @media(max-width:575px) {
        min-height: 720px;
    }

    &:before {
        display: none;
    }

    .wpo-event-wrap {
        max-width: 900px;
        background: linear-gradient(140deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.5) 100%);
        -webkit-backdrop-filter: blur(12px);
        backdrop-filter: blur(12px);
        position: relative;

        .wpo-event-item {
            @media(max-width:575px) {
                padding: 40px 15px 40px;
            }
        }
    }

    .coming-soon-section {

        // wpo-wedding-date

        .wpo-wedding-date {
            text-align: center;
            padding-bottom: 0;
            margin-top: 20px;
            margin-bottom: 50px;

            @include media-query(1199px) {
                margin-top: 20px;
            }

            @include media-query(991px) {
                display: flex;
                justify-content: center;
                margin-top: 0;
            }

            #clock,
            #clock3 {
                overflow: hidden;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                @include media-query(530px) {
                    margin-top: 0;
                }

                .time-section {
                    float: left;
                    display: table;
                    width: 80px;

                    @include media-query(450px) {
                        width: 52px;
                    }
                }

                .time-section+.time-section {
                    margin-left: 30px;

                    @include media-query(991px) {
                        margin-left: 10px;
                    }

                    @include media-query(767px) {
                        margin-left: 10px;
                    }
                }

                .time {
                    font-family: $heading-font;
                    font-size: 60px;
                    line-height: 1em;
                    padding-top: 15px;
                    color: $theme-primary-color;

                    @include media-query(1300px) {
                        font-size: 50px;
                    }

                    @include media-query(1100px) {
                        font-size: 40px;
                    }

                    @include media-query(991px) {
                        font-size: 50px;
                    }

                    @include media-query(767px) {
                        font-size: 40px;
                    }
                }

                span {
                    color: $text-color;
                    font-size: 20px;

                    @media(max-width:991px) {
                        font-size: 20px;
                    }

                    @media(max-width:575px) {
                        font-size: 18px;
                    }
                }
            }
        }


        .coming-soon-text {
            text-align: center;

            h2 {
                font-size: 60px;
                margin-bottom: 20px;

                @include media-query(1199px) {
                    font-size: 50px;
                }

                @include media-query(767px) {
                    font-size: 30px;
                }
            }

            p {
                margin-bottom: 0;
            }
        }

        .wpo-coming-contact {
            .form-control {
                width: 100%;
                height: 55px;
                background: none;
                border: 1px solid #a9a1a1;
                margin-bottom: 10px;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .error {
                margin-bottom: 10px;
            }

            button {
                height: 55px;
                background: $theme-primary-color-s2;
                border: none;
                color: $white;
                width: 100%;
                font-size: 16px;
            }
        }
    }
}