@mixin background-style($path, $position, $bg-size, $repeat, $attachment ) {
    background: url($path) $position/$bg-size $repeat $attachment;
}

@mixin pattern-background($color, $path) {
    background: $color url($path);
}

@mixin overlay($bg-color) {
    background-color: $bg-color;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

@mixin rounded-border($amount: 50%) {
    -webkit-border-radius: $amount;
    -moz-border-radius: $amount;
    -o-border-radius: $amount;
    -ms-border-radius: $amount;
    border-radius: $amount;
}

@mixin skewingx($amount ) {
    -webkit-transform: skewX($amount);
    -moz-transform: skewX($amount);
    -o-transform: skewX($amount);
    -ms-transform: skewX($amount);
    transform: skewX($amount);
}

@mixin translatingX($amount: -50%) {
    -webkit-transform: translateX($amount);
    -moz-transform: translateX($amount);
    -o-transform: translateX($amount);
    -ms-transform: translateX($amount);
    transform: translateX($amount);
}


@mixin translatingY($amount: -50%) {
    -webkit-transform: translateY($amount);
    -moz-transform: translateY($amount);
    -o-transform: translateY($amount);
    -ms-transform: translateY($amount);
    transform: translateY($amount);
}

@mixin center-by-translating() {
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@mixin transition-time($time: 0.5s, $prop: all) {
    -webkit-transition: $prop $time;
    -moz-transition: $prop $time;
    -o-transition: $prop $time;
    -ms-transition: $prop $time;
    transition: $prop $time;
}

@mixin round-box-shadow($color, $blur: 5px, $sprade: 5px) {
    -webkit-box-shadow: 0 0 $blur $sprade $color;
    -moz-box-shadow: 0 0 $blur $sprade $color;
    -o-box-shadow: 0 0 $blur $sprade $color;
    -ms-box-shadow: 0 0 $blur $sprade $color;
    box-shadow: 0 0 $blur $sprade $color;
}

@mixin widther($length ) {
    @media screen and (min-width: $length) {
        @content;
    }
}

@mixin media-query($length ) {
    @media (max-width: $length) {
        @content;
    }
}


@mixin mq-min-width($length ) {
    @media (min-width: $length) {
        @content;
    }
}


@mixin placeholder-style($color, $font-size: 14px, $font-style: italic) {
    ::-webkit-input-placeholder {
        font-style: $font-size;
        font-style: $font-style;
        color: $color;
    }

    :-moz-placeholder {
        font-style: $font-size;
        font-style: $font-style;
        color: $color;
    }

    ::-moz-placeholder {
        font-style: $font-size;
        font-style: $font-style;
        color: $color;
    }

    :-ms-input-placeholder {
        font-style: $font-size;
        font-style: $font-style;
        color: $color;
    }
}


@mixin linear-gradient-bg($direction, $gradients...) {
    background: -webkit-linear-gradient($direction, $gradients);
    background: -moz-linear-gradient($direction, $gradients);
    background: -o-linear-gradient($direction, $gradients);
    background: -ms-linear-gradient($direction, $gradients);
    background: linear-gradient($direction, $gradients);
}