/* Global Styles */
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.birthday-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1c1c1c;
  padding: 1rem 1rem;
  width: 100%;
}

.header-logo {
  height: 1.5rem;
  width: auto;
}

.buy-now-button {
  background-color: #82b440;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buy-now-button:hover {
  background-color: #71a029;
}

/* Main Content */
.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30rem; /* max-w-md */
  margin: 8rem auto auto auto;
  padding: 2rem;
  text-align: center;
}

/* Image Container with Blob */
.image-container {
  // position: relative;
  //width: 16rem;
  width: 100%;
  height: 16rem;
  margin-bottom: 3rem;
}

.blob-background {
  // position: absolute;
  // top: -100px;
  // left: 60%;
  // transform: translate(-50%, 50%);
  background-size: 200px 200px;
  //border-radius: 80% 20% 32% 68% / 58% 22% 78% 42%;
  border-radius: 5px;
  height: 230px;
  //width: 230px;
  width: 100%;
  background-image: url("../../images/birthday/Jqueen.JPG");
  background-size: cover;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.birthday-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.crown-icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 6rem;
  height: 6rem;
  color: #4fc3f7; /* Light blue */
}

/* Title */
.title {
  // font-size: 2.25rem;
  font-weight: bold;
  color: #1b2b4d;
  margin-bottom: 1.5rem;
}

/* Description */
.description {
  color: #4b4b4b;
  margin-bottom: 2rem;
  line-height: 1.8;
}

/* Social Icons */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.social-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.facebook-icon {
  color: #f06292; /* Pink color */
}

.twitter-icon {
  color: #f06292; /* Pink color */
}

.instagram-icon {
  color: #f06292; /* Pink color */
}

/* Read More Button */
.read-more-button {
  background-color: #42a5f5;
  color: white;
  padding: 1rem 3rem;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.read-more-button:hover {
  background-color: #1e88e5;
}

.date-button {
  margin: 20px 0;
  background-color: $theme-primary-color-s3; /* Light blue background */
  color: white; /* Dark blue text */
  font-size: 1.25rem; /* Text size xl */
  padding: 0.5rem 2rem; /* Padding */
  border-radius: 9999px; /* Fully rounded corners */
  border: none; /* Remove border */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.testimonial-card {
  max-width: 500px; /* Adjust as needed */
  margin: 0 auto;
  padding: 20px;
}

.testimonial-card__content {
  background-color: #87ceeb;
  border-radius: 20px;
  padding: 20px;
  overflow: hidden;
}
.pink-bg {
  background-color: #feb9cc;
}

.testimonial-card__image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* Aspect ratio 1:1 */
}

.testimonial-card__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.testimonial-card__text {
  color: white;
  margin-top: 24px;
}

.testimonial-card__quote {
  font-size: 18px;
  line-height: 1.5;
}

.testimonial-card__author {
  margin-top: 24px;
}

.testimonial-card__author-name {
  font-size: 24px;
  font-weight: bold;
}

.testimonial-card__author-title {
  font-size: 16px;
  opacity: 0.9;
}
