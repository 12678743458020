.multi-section-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.corner-images-section {
  position: relative;
  height: 100vh;
  background-color: $creme;
  padding: 20px;
  margin-bottom: 20px;
}

.corner-image {
  position: absolute;
  width: 200px;
  height: 200px;
  object-fit: cover;

  @include mq-min-width(575px) {
    width: 250px;
    height: 250px;
  }
  @include mq-min-width(991px) {
    width: 300px;
    height: 300px;
  }
}

.top-left {
  top: -77px;
  left: -80px;
}

.top-right {
  top: -77px;
  right: -80px;
}

.bottom-left {
  bottom: -24px;
  left: -57px;
}

.bottom-right {
  bottom: -24px;
  right: -57px;
  transform: rotateY(180deg);
}

.hero-container {
  background-color: transparent;
  height: 900px;
  background-size: cover !important;

  @media (max-width: 1400px) {
    height: 800px;
  }

  @media (max-width: 1044px) {
    height: 720px;
  }

  @media (max-width: 991px) {
    height: auto;
    padding-bottom: 0;
  }
}

.section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height: 100%;
  text-align: center;

  .circleImg {
    display: flex;
    align-items: center;

    width: 350px;
    height: 400px;

    @media (max-width: 1199px) {
      margin-top: 70px;
    }

    @media (max-width: 530px) {
      width: 300px;
      height: 300px;
      margin-top: 60px;
    }

    @media (max-width: 375px) {
      width: 250px;
      height: 250px;
      margin-top: 60px;
    }

    h2 {
      font-size: 60px;
      @include media-query(1100px) {
        font-size: 50px;
        margin-bottom: 10px;
      }

      @include media-query(530px) {
        font-size: 30px;
      }
    }

    img {
      width: 100%;
      @media (max-width: 530px) {
        width: 85%;
      }
    }
  }

  .circleShape {
    top: 10px !important;
  }
  .title-container {
    margin: 45px 0;

    @media (max-height: 667px) {
      margin: 10px 0;
    }

    .title {
      // font-family: "Jost", sans-serif;
      font-size: 2.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .wedding-date {
    background-color: transparent;
    box-shadow: none;
  }
}

.regular-section {
  background-color: #e0e0e0;
  padding: 20px;
  margin-bottom: 20px;
}

h2 {
  color: #333;
  margin-bottom: 10px;
}

p {
  color: #666;
  line-height: 1.6;
}
